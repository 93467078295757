.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For Webkit (Chrome, Safari, Edge) */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #888;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.slick-slide {
  transition: all 0.3s ease;
  opacity: 0.6;
}

@media (max-width: 600px) {
  .slick-slide {
    opacity: 1;
  }
}


.slick-center {
  transform: scale(1.1);
  opacity: 1 !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.testimonial-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  padding: 15px;
  text-align: center;
  height: 100%;
}

.testimonial-text {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  margin: 15px 0;
}

.testimonial-name {
  font-weight: bold;
  color: #333;
}

.slick-dots li button:before {
  color: #888;
}

.slick-dots li.slick-active button:before {
  color: #e86252;
}

.slick-slide:not(.slick-center) {
  transform: scale(0.9); /* Scale down non-center slides */
  opacity: 0.6; /* Less opacity for non-center slides */
}

@media (max-width: 600px) {
  .slick-slide:not(.slick-center) {
    transform: scale(0.9); /* Scale down non-center slides */
    opacity: 1; /* Less opacity for non-center slides */
  }
}

.main-slide {
  width: 100%;
}


